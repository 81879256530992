<template>
  <b-card>
    <!-- Modal -->
    <b-modal
      v-model="dialog"
      size="sm"
      :title="modalTitle"
      ok-title="Save"
      header-close-content=""
      :ok-disabled="isDisableBtn"
      @ok="save"
      @cancel="close"
    >
      <b-container fluid>
        <b-row>
          <!-- deposit_amount  -->
          <b-col cols="12" v-if="isMarketing">
            <!-- {{ amount }} -->
            <label for="amountInput">Deposit Amount:</label>
            <b-form-select id="amountInput" v-model="amount" :options="marketingDeposit"></b-form-select>
          </b-col>
          <b-col cols="12" v-else>
            <!-- {{ amount }} -->
            <label for="amountInput">Deposit Amount:</label>
            <input
              id="amountInput"
              v-model="amount"
              name="amount"
              min="0"
              v-currency="{ currency: 'VND', locale: 'vi' }"
              data-vv-as="Amount"
              :class="{
                input: true,
                'is-danger': errors.has('amount')
              }"
              v-validate="{ required: true }"
            />
            <span v-show="errors.has('amount')" class="is-danger-text position-relative">{{
              errors.first('amount')
            }}</span>
            <!-- <span class="is-danger-text position-relative"
              >Min value: 1.000₫</span
            > -->
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- Header -->
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">Deposit Transactions List</h4>
      </b-col>
      <!-- Filter UserName -->
      <b-col>
        <b-form-group id="input-name-filter" label="UserName" label-for="username-filter">
          <b-form-input
            id="username-filter"
            v-model="filter_username"
            type="text"
            placeholder="Search username ..."
            @input="onFilterName"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-table
      striped
      :fields="ortherMarketing()"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table text-center orderTable"
    >
      <template v-slot:cell(total_balance)="item">
        {{ formatPrice(item.item.total_balance) }}
      </template>
      <template v-slot:cell(total_deposit)="item">
        {{ formatPrice(item.item.total_deposit) }}
      </template>
      <template v-slot:cell(total_paid)="item">
        {{ formatPrice(item.item.total_paid) }}
      </template>
      <template v-slot:cell(total_refund)="item">
        {{ formatPrice(item.item.total_refund) }}
      </template>
      <template v-slot:cell(actions)="item">
        <div @click="editItem(item.item)" style="cursor: pointer">
          <v-icon class="text-success">mdi-plus</v-icon>
          <v-icon class="text-success">mdi-cash</v-icon>
        </div>
      </template>
    </b-table>
    <!-- Footer -->
    <b-row>
      <b-col cols="5">
        Showing {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        of {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form inline style="margin-right: 20px; margin-bottom: 1rem">
          <label class="mr-sm-2" for="per-page">Rows per page: </label>
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            @change="fetchData"
          ></b-form-select>
        </b-form>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import ApiService from '@/common/api.service.js';
import _ from 'lodash';
import axios from 'axios';
export default {
  name: 'deposit_manual',
  components: {},
  data() {
    return {
      isFilter: false,
      isBusy: false,
      filter_username: '',
      headers: [
        { text: 'Id', key: 'id' },
        { text: 'Name', key: 'name' },
        // { text: "Phone", key: "phone" },
        { text: 'User Name', key: 'username' },
        { text: 'total_balance', key: 'total_balance' },
        { text: 'total_deposit', key: 'total_deposit' }
        // { text: "total_paid", key: "total_paid" },
        // { text: "total_refund", key: "total_refund" },
        // { text: "Actions", key: "actions", class: "text-center" }
      ],
      // Modal data
      items: [],
      modalTitle: '',
      // Modal validate
      rows: 0,
      perPage: 10,
      currentPage: 1,
      editedItem: {
        id: '',
        name: '',
        phone: '',
        username: '',
        total_balance: '',
        total_deposit: '',
        total_paid: '',
        total_refund: ''
      },
      amount: '',
      dialog: false,
      editedIndex: -1,
      marketingDeposit: [
        { value: 10000, text: '10.000đ' },
        { value: 20000, text: '20.000đ' },
        { value: 50000, text: '50.000đ' },
        { value: 100000, text: '100.000đ' }
      ]
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    editedItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    },
    amount: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.amount = newVal;
      }
    }
  },
  computed: {
    isDisableBtn() {
      return !this.amount || this.errors.has('amount');
    },
    isMarketing() {
      return this.$store.getters.getMarketing;
    },
    marketingHeaders() {
      return this.headers;
    }
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Deposit Manual' }]);
  },
  methods: {
    onFilterName: _.debounce(function () {
      this.fetchData();
    }, 500),
    fetchData() {
      this.isBusy = true;
      let limit = this.perPage;
      let offset = this.currentPage;
      ApiService.setHeader();
      ApiService.get('accounts', `?q=${this.filter_username}&limit=${limit}&offset=${offset}`)
        .then((resp) => {
          this.items = resp.data.data.accountList.map((ads) => {
            return {
              ...ads
            };
          });
          this.rows = resp.data.data.total;
          this.isBusy = false;
        })
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
          this.isBusy = false;
        });
    },
    editItem: function (item) {
      this.dialog = true;
      this.modalTitle = 'Add Deposit Manual';
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = {
        ...item
      };
    },

    close() {
      this.dialog = false;
      this.editedItem = {
        id: '',
        name: '',
        phone: '',
        username: '',
        total_balance: '',
        total_deposit: '',
        total_paid: '',
        total_refund: ''
      };
      this.amount = '';
      this.editedIndex = -1;
    },
    depositManual: _.debounce(function () {
      let amountData = this.amount;
      if (amountData) {
        if (isNaN(amountData)) amountData = +this.amount.split('₫')[0].replaceAll('.', '');
        let manualData = {
          amount: amountData || undefined,
          account_id: this.items[this.editedIndex].id
        };
        if (this.editedIndex > -1) {
          // Update roles
          axios({
            method: 'POST',
            url: `deposit-transactions`,
            data: manualData
          })
            .then((response) => {
              if (response.status === 200) {
                this.editedItem.total_balance = response.data.data.total_balance;
                this.editedItem.total_deposit = response.data.data.total_deposit;
                Object.assign(this.items[this.editedIndex], this.editedItem);
                this.close();
                this.$bvToast.toast('Deposit Manual added successfully', {
                  title: null,
                  variant: 'success',
                  autoHideDelay: 3000,
                  noCloseButton: true
                });
              } else {
                this.$bvToast.toast(`Errors`, {
                  title: null,
                  variant: 'danger',
                  autoHideDelay: 3000,
                  noCloseButton: true
                });
              }
            })
            .catch((errors) => {
              if (errors) {
                this.$bvToast.toast(`${errors}`, {
                  title: null,
                  variant: 'danger',
                  autoHideDelay: 3000,
                  noCloseButton: true
                });
              }
            });
        }
      }
    }, 500),
    save(bvModalEvt) {
      // Cancel auto close
      bvModalEvt.preventDefault();
      // Get new brand/cate/vendor name for data-table
      this.depositManual();
    },
    ortherMarketing() {
      if (!this.isMarketing) {
        return this.headers.concat([
          { text: 'Phone', key: 'phone' },
          { text: 'total_paid', key: 'total_paid' },
          { text: 'total_refund', key: 'total_refund' },
          { text: 'Actions', key: 'actions', class: 'text-center' }
        ]);
      } else {
        return this.headers.concat([{ text: 'Actions', key: 'actions', class: 'text-center' }]);
      }
    }
  }
};
</script>

<style lang="scss">
#amountInput {
  width: 100%;
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e2e5ec;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #9aabff;
    outline: 0;
  }
}
</style>
